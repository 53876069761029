<template>
  <div class="parallax" :class="parallaxClass">
    <div class="bg"></div>
    <div class="parallax-content">
      <h4>Conoce, Juega, Repite</h4>
      <!-- <p><cite>Nunca jueges solo.</cite></p> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { doOnFrame } from "../utils/style";
import { isiPhone } from "../utils/browser";

export default defineComponent({
  name: "Parallax",
  props: {
    deltaTop: {
      type: Number,
      required: false,
      default: 0.2,
    },
  },
  data() {
    return {
      currentScrollY: 0,
      parrallaxTop: 0,
    };
  },
  created() {
    // window.addEventListener("scroll", this.handlerScroll);
  },
  unmounted() {
    // window.removeEventListener("scroll", this.handlerScroll);
  },
  computed: {
    parallaxStyles(): any {
      return {
        transform: `translateY(${this.parrallaxTop}px)`,
      };
    },
    parallaxClass() {
      return {
        "no-attachment": isiPhone(),
      };
    },
  },
  // TODO
  methods: {
    handlerScroll() {
      if (this.currentScrollY != window.scrollY) {
        doOnFrame(this.parallaxCalculations);
        this.currentScrollY = window.scrollY;
      }
    },
    parallaxCalculations() {
      if (window.scrollY <= window.innerHeight) {
        this.parrallaxTop = Math.round(window.scrollY * this.deltaTop);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.parallax {
  position: relative;
  /* The image used */
  background-image: url("../assets/images/parallax.jpg");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
 background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  // background-attachment: scroll;
}

.no-attachment {
  background-attachment: unset;
}

.bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  opacity: 0.5;
  background-color: rgb(21, 21, 21);
}
.parallax-content {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
  z-index: 3;
  color: white;
}
h4 {
  // font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
}
</style>