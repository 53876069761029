<template>
  <header>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav>
            <a
              class="menu-trigger"
              :class="{ active: openMenu }"
              @click="openMenu = !openMenu"
            >
              <span>Menu</span>
            </a>
            <img class="logo" src="@/assets/images/brand.png" />
          </nav>
        </div>
      </div>
      <Menu v-if="openMenu" @closeMenu="openMenu = !openMenu" />
    </div>
  </header>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Menu from "@/components/Menu.vue";

export default defineComponent({
  name: "Header",
  components: {
    Menu,
  },
  data() {
    return {
      openMenu: false,
    };
  }
});
</script>
<style scoped lang="scss">
header {
  background-color: #fff;
  height: 72px;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
  z-index: 10;
  transition: opacity 750ms cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 500ms cubic-bezier(0, 0.61, 0.28, 0.92);
  width: 100%;
}
.logo {
  top: 0;
  right: 0;
  width: 150px;
  position: absolute;
}
.menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 23px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  left: 40px;
}

.menu-trigger span,
.menu-trigger span:before,
.menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.menu-trigger span,
.menu-trigger span:before,
.menu-trigger span:after {
  background-color: #1e1e1e;
}

.menu-trigger span:before,
.menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.menu-trigger span:before,
.menu-trigger span:after {
  background-color: #1e1e1e;
}

.menu-trigger span:before,
.menu-trigger span:after {
  content: "";
}

.menu-trigger span {
  top: 16px;
}

.menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.menu-trigger.active span,
.menu-trigger.active span:before,
.menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #f57c00;
}

.menu-trigger.active span:before {
  background-color: #f57c00;
}

.menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #f57c00;
}

.menu-trigger.active span:after {
  background-color: #f57c00;
}
</style>