<template>
  <div class="d-flex justify-content-center">
    <a href="https://apps.apple.com/es/app/pickapp-basketball/id1565955646" target="_blank" >
    <!-- <a href="#"> -->
      <img src="@/assets/images/app-store-available.png" alt="" />
      <!-- <img src="@/assets/images/app-store.png" alt="" /> -->
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.pickapp.basketball" style="margin-left: 16px" target="_blank">
    <!-- <a href="#" style="margin-left: 16px"> -->
      <img src="@/assets/images/google-play-available.png" alt="" />
      <!-- <img src="@/assets/images/google-play.png" alt="" /> -->
    </a>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DownloadButtons",
});
</script>
<style scoped lang="scss">
img {
  height: 48px;
}
</style>