
import { defineComponent } from "vue";
import NotFoundIcon from "@/assets/svg/ball.svg";
import DownloadButtons from "@/components/DownloadButtons.vue";

export default defineComponent({
  components: {
    NotFoundIcon,
    DownloadButtons
  },
});
