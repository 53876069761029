export const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return new RegExp(/^((?!chrome|android).)*safari/g).test(ua);
}

export const isChrome = () => {
    const ua = navigator.userAgent.toLowerCase();
    return new RegExp(/chrome/g).test(ua);
}

export const isiPhone = () => {
    const ua = navigator.userAgent.toLowerCase();
    return new RegExp(/iphone/g).test(ua);
}