import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faStar } from '@fortawesome/free-solid-svg-icons'
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faTiktok
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Header from '@/layout/Header.vue';
import Footer from '@/layout/Footer.vue';
import { defaultLocale, languages } from './assets/i18n/index';
import { createI18n } from "vue-i18n";

const messages = Object.assign(languages)
const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: 'es',
  messages
})

library.add(faHome, faStar, faTwitter, faFacebook, faInstagram, faTiktok)

createApp(App)
  .use(i18n)
  .use(router)
  .use(VueAxios, axios)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("app-header", Header)
  .component("app-footer", Footer)
  .mount('#app')

console.log("version 1.2.1");