import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import Cookies from '../views/Cookies.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import About from '../views/About.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Contact from '../views/Contact.vue'
import Verified from '../views/Verified.vue';
import NotFound from '../views/NotFound.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: Cookies
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/verified',
    name: 'Verified',
    component: Verified
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }

})

export default router
