
import { defineComponent } from "vue";
import { doOnFrame } from "../utils/style";
import { isiPhone } from "../utils/browser";

export default defineComponent({
  name: "Parallax",
  props: {
    deltaTop: {
      type: Number,
      required: false,
      default: 0.2,
    },
  },
  data() {
    return {
      currentScrollY: 0,
      parrallaxTop: 0,
    };
  },
  created() {
    // window.addEventListener("scroll", this.handlerScroll);
  },
  unmounted() {
    // window.removeEventListener("scroll", this.handlerScroll);
  },
  computed: {
    parallaxStyles(): any {
      return {
        transform: `translateY(${this.parrallaxTop}px)`,
      };
    },
    parallaxClass() {
      return {
        "no-attachment": isiPhone(),
      };
    },
  },
  // TODO
  methods: {
    handlerScroll() {
      if (this.currentScrollY != window.scrollY) {
        doOnFrame(this.parallaxCalculations);
        this.currentScrollY = window.scrollY;
      }
    },
    parallaxCalculations() {
      if (window.scrollY <= window.innerHeight) {
        this.parrallaxTop = Math.round(window.scrollY * this.deltaTop);
      }
    },
  },
});
