
import { defineComponent } from "vue";
import DownloadButtons from "@/components/DownloadButtons.vue";

export default defineComponent({
  name: "Welcome",
  components: {
    DownloadButtons,
  },
});
