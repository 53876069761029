<template>
  <section class="page-content container">
    <h1>Política de cookies</h1>
    <p><strong>¿Qué son las cookies?</strong></p>
    <p>
      Una cookie es un fichero que se descarga en su ordenador al acceder a
      determinadas páginas web. Las cookies permiten a una página web, entre
      otras cosas, almacenar y recuperar información sobre los hábitos de
      navegación de un usuario o de su equipo y, dependiendo de la información
      que contengan y de la forma en que utilice su equipo, pueden utilizarse
      para reconocer al usuario.
    </p>
    <p><strong>Cookies propias y de terceros</strong></p>
    <p>
      Las cookies propias pertenecen a PickApp. En lo que respecta a las cookies
      de terceros, las instala otra entidad en tu dispositivo a través de
      nuestro servicio. Puede darse el caso de que una entidad que desempeñe
      cierta actividad para PickApp instale cookies de terceros en tu
      dispositivo, por ejemplo, para ayudarnos a comprender mejor el uso que se
      hace de nuestro servicio. Nuestros asociados también pueden insertar
      cookies de terceros en tu dispositivo para anunciar productos y servicios
      desde cualquier ubicación de internet.
    </p>
    <p><strong>¿Qué tipo de cookies usamos?</strong></p>
    <p>
      Usamos cookies de análisis&nbsp;que nos permiten cuantificar el número de
      usuarios y así realizar la medición y análisis estadístico de la
      utilización que hacen los usuarios del servicio ofertado. Para ello se
      analiza su navegación en nuestra página web con el fin de mejorar la
      oferta de servicios que le ofrecemos.
    </p>
    <p><strong>¿Cómo desactivar las Cookies?</strong></p>
    <p>
      Puede usted permitir, bloquear o eliminar las cookies instaladas en su
      equipo mediante la configuración de las opciones del navegador instalado
      en su ordenador.
    </p>
    <p>
      Puede acceder a la configuración de los navegadores webs más frecuentes
      para aceptar, instalar o desactivar las cookies.
    </p>
    <ul class="d-flex flex-column">
      <li>
        <a href="https://support.google.com/chrome/answer/95647"
          >Google Chrome</a
        >
      </li>
      <li>
        <a
          href="https://support.microsoft.com/en-ie/help/17442/windows-internet-explorer-delete-manage-cookies"
          >Internet Explorer</a
        >
      </li>
      <li>
        <a
          href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preference"
          >Firefox</a
        >
      </li>
      <li>
        <a href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
          >Safari</a
        >
      </li>
      <li>
        <a href="https://support.apple.com/en-us/HT201265">Safari Mobile</a>
      </li>
      <li>
        <a
          href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"
          >Opera</a
        >
      </li>
    </ul>
  </section>
</template>
<style scoped lang="scss">
h1{
  margin-bottom: 20px;
}
p {
  text-align: justify;
}
ul {
  text-align: left;
}
</style>