<template>
  <section
    class="page page-content flex-column container d-flex justify-content-center align-items-center"
  >
    <template v-if="showContact">
      <h1 class="mb-3">{{ $t("MENU.CONTACT") }}</h1>
      <form @submit="sendMessage">
        <div class="row">
          <div class="col-12 p-3">
            <input
              type="email"
              v-model="form.email"
              class="form-control"
              placeholder="Email"
            />
          </div>
          <div class="col-12 p-3">
            <input
              type="text"
              v-model="form.subject"
              class="form-control"
              placeholder="Asunto"
            />
          </div>
          <div class="col-12 p-3">
            <textarea
              type="text"
              v-model="form.message"
              class="form-control"
              placeholder="Mensaje"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              :disabled="v$.$invalid"
              class="btn btn-color"
              v-if="!loading"
            >
              {{ $t("CONTACT.SEND") }}
            </button>
            <div class="spinner-border" role="status" v-else></div>
          </div>
        </div>
      </form>
    </template>
    <template v-else>
      <div class="d-flex flex-column p-3">
        <h1>{{ $t("CONTACT.TITLE") }}</h1>
        <p class="text-center">{{ $t("CONTACT.TEXT") }}</p>
      </div>
    </template>
    <Toast
      v-if="showToast"
      color="bg-danger"
      :text="'CONTACT.ERROR'"
      @close="showToast = false"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import ContactDto from "../models/ContactDto";
import { required, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import environment from "../environment/environment";

export default defineComponent({
  name: "Contact",
  data() {
    return {
      showContact: true,
      showToast: false,
      loading: false,
      form: {
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      message: {
        required,
      },
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  methods: {
    sendMessage(e: any) {
      e.preventDefault();
      this.showLoading(true);
      this.callApi(
        new ContactDto({
          subject: this.form.subject,
          email: this.form.email,
          message: this.form.message,
        })
      ).then(
        (data) => {
          this.showLoading(false);
          this.showContact = false;
        },
        (err) => {
          this.showLoading(false);
          this.onShowToast();
        }
      );
    },
    onShowToast() {
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    },
    showLoading(show: boolean) {
      this.loading = show;
    },
    async callApi(dto: ContactDto) {
      const URL = `${environment.CONTACT_URL}/contact`;
      return await axios.post(URL, dto);
    },
  },
});
</script>

<style scoped lang="scss">
textarea {
  resize: none;
  height: 200px;
}
.btn-color {
  background: #f57c00;
}
.spinner-border {
  color: #f57c00;
}
</style>