<template>
  <section class="page page-content container d-flex flex-column">
    <h1>{{ $t("ABOUT.TITLE") }}</h1>
    <div class="d-flex flex-column flex-grow-1 justify-content-center">
      <p>
        <strong>¿Qué es PickApp?</strong>
      </p>
      <p>
        PickApp es una red social enfocada al mundo del baloncesto. Podrás
        conocer una red amplia de jugadores cerca de ti, localizar decenas de
        canastas allá donde estés, organizar partidos o bien unirte a aquellos
        que hayan sido creados por otros jugadores.
      </p>
      <p>
        <strong>¿Qué me llevó a crear PickApp?</strong>
      </p>
      <p>Eric Retamero, CEO y fundador.</p>
      <p>
        <cite>
        Llevo jugando a baloncesto desde los 5 años. Siempre he sido un
        apasionado de este deporte. Aparte de haber jugado en varios clubes,
        siempre me ha encantado el streetbasket, el poder jugar al aire libre y
        conocer nuevos jugadores. Un día me encontré con un problema, que se fue
        repitiendo cada vez más. No podía jugar a baloncesto porque mis amigos o
        trabajaban o no éramos los suficientes para jugar un partido. Así que,
        como soy programador informático, decidí crear esta plataforma para
        cubrir esa necesidad que a mi y a muchos nos ocurre.
        </cite>
      </p>
    </div>
  </section>
</template>

<style scoped lang="scss">
h1 {
  margin-bottom: 20px;
}
p {
  text-align: justify;
}
ul {
  text-align: left;
}
</style>
