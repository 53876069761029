
import { defineComponent } from "vue";
import BasketballCourtIcon from "@/assets/svg/basketball-court.svg";
import ChatBubbleIcon from "@/assets/svg/chat.svg";
import FiltersIcon from "@/assets/svg/filters.svg";
import RatingIcon from "@/assets/svg/favorites.svg";
import BallIcon from "@/assets/svg/ball.svg";

export default defineComponent({
  name: "Feature",
  components: {
    BasketballCourtIcon,
    ChatBubbleIcon,
    FiltersIcon,
    RatingIcon,
    BallIcon,
  },
  FEATURES: [
    {
      TITLE: "FEATURES.COURT.TITLE",
      TEXT: "FEATURES.COURT.TEXT",
    },
    {
      TITLE: "FEATURES.GAME.TITLE",
      TEXT: "FEATURES.GAME.TEXT",
    },
    {
      TITLE: "FEATURES.CHAT.TITLE",
      TEXT: "FEATURES.CHAT.TEXT",
    },
    {
      TITLE: "FEATURES.FILTER.TITLE",
      TEXT: "FEATURES.FILTER.TEXT",
    },
    {
      TITLE: "FEATURES.SUGGESTION.TITLE",
      TEXT: "FEATURES.SUGGESTION.TEXT",
    },
  ],
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    changeTab(index: number) {
      this.activeTab = index;
    },
  },
});
