<template>
  <section class="section" id="download">
    <h1 class="mb-5">{{ $t("DOWNLOAD") }}</h1>
    <!-- <h1 class="mb-5">{{ $t("COMMING_SOON") }}</h1> -->
    <DownloadButtons />
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import DownloadButtons from "@/components/DownloadButtons.vue";

export default defineComponent({
  name: "Download",
  components: {
    DownloadButtons,
  },
});
</script>
<style scoped lang="scss">
#download {
  padding-bottom: 120px;
  // background-image: url(../images/footer-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
}

.h1 {
  letter-spacing: -1px;
  font-size: calc(44px + (16 * ((100vw - 375px) / 1225)));
  line-height: calc(56px + (8 * ((100vw - 375px) / 1225)));
}
</style>