<template>
  <footer class="section container" id="footer">
    <div class="row">
      <div class="col-7 col-md-4 mb-4" style="text-align: left">
        <ul class="d-flex flex-column">
          <li class="mb-4">
            <router-link class="footer-link" to="/about">{{
              $t("MENU.ABOUT")
            }}</router-link>
          </li>
          <li>
            <router-link class="footer-link" to="/contact">{{
              $t("MENU.CONTACT")
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="col-5 col-md-4 mb-4" style="text-align: left">
        <ul class="d-flex flex-column">
          <li class="mb-4">
            <a
              class="footer-link"
              href="http://blog.pickappbasketball.com/"
              target="_blank"
              >{{ $t("MENU.BLOG") }}</a
            >
          </li>
          <li>
            <a
              class="footer-link"
              href="http://ayuda.pickappbasketball.com/"
              target="_blank"
              >{{ $t("MENU.HELP") }}</a
            >
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-4">
        <ul class="social-icon">
          <li>
            <a target="_blank" href="https://www.facebook.com/pickappbasketball" class="tran3s">
              <font-awesome-icon
                :icon="['fab', 'facebook']"
              ></font-awesome-icon>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://twitter.com/pickappbasket" class="tran3s">
              <font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/pickappbasketball/"
              class="tran3s"
            >
              <font-awesome-icon
                :icon="['fab', 'instagram']"
              ></font-awesome-icon>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.tiktok.com/@pickappbasketball"
              class="tran3s"
            >
              <font-awesome-icon :icon="['fab', 'tiktok']"></font-awesome-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <ul class="footer-menu">
          <li class="menu-item">
            <router-link to="/terms-and-conditions">{{
              $t("FOOTER.TERMS_CONDITIONS")
            }}</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/cookies">{{ $t("FOOTER.COOKIES") }}</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/privacy-policy" ex
              >{{ $t("FOOTER.PRIVACY_POLICY") }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md-2 d-md-none">
        <span class="country">España</span>
      </div>
      <div class="col-6 col-md-4 copyright-wrapper">
        <span class="country d-none d-md-inline d-lg-inline">España</span>
        <span class="copyright">{{ $t("FOOTER.COPYRIGHT", { year: new Date().getFullYear() }) }}</span>
      </div>
    </div>
  </footer>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
});
</script>
<style scoped lang="scss">
.row {
  margin: 0;
}
ul {
  padding: 0;
}
#footer {
  padding-top: 120px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
}
.footer-link {
  color: #000;
  font-size: 18px;
  line-height: 24px;
}

.footer-logo .logo h3 {
  color: #353535;
}

.footer-menu li a {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.footer-menu {
  .menu-item {
    margin-right: 4px;
    ::after {
      content: "|";
      margin-left: 4px;
    }
  }
  .menu-item:last-child {
    ::after {
      content: none;
      margin-left: 0;
    }
  }
}

.logo a {
  color: #f57c00;
}
.copyright {
  font-size: 14px;
}
.country {
  font-size: 14px;
    margin-right: 8px;
}

.tran3s {
  color: #d6d6d6;
  margin: 5px 0 0 22px;
  font-size: 20px;
}

@media (min-width: 576px) {
  .footer-menu {
    text-align: left;
  }
  .copyright-wrapper, .social-icon {
    text-align: end;
  }
}

</style>