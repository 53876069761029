<template>
  <section class="section" id="about">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-4 mb-4 col-md-6 col-sm-12 col-xs-12"
          data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
        >
          <div class="features-item">
            <div class="features-icon">
              <img src="@/assets/images/hoop.png" alt="" />
              <h4>{{ $t("SERVICE.COURT.TITLE") }}</h4>
              <p>{{ $t("SERVICE.COURT.TEXT") }}</p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 mb-4 col-md-6 col-sm-12 col-xs-12"
          data-scroll-reveal="enter bottom move 30px over 0.6s after 0.4s"
        >
          <div class="features-item">
            <div class="features-icon">
              <img src="@/assets/images/player.png" alt="" />
              <h4>{{ $t("SERVICE.PLAYER.TITLE") }}</h4>
              <p>{{ $t("SERVICE.PLAYER.TEXT") }}</p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 mb-4 col-md-6 col-sm-12 col-xs-12"
          data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
        >
          <div class="features-item">
            <div class="features-icon">
              <img src="@/assets/images/trophy.png" alt="" />
              <h4>{{ $t("SERVICE.GAME.TITLE") }}</h4>
              <p>{{ $t("SERVICE.GAME.TEXT") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Service",
});
</script>
<style scoped lang="scss">
#about {
  padding: 100px 0px;
  position: relative;
  z-index: 9;
}
.features-item h4 {
  margin-top: 0px;
  font-size: 22px;
  color: #1e1e1e;
  margin-bottom: 25px;
}
.features-item {
  border-radius: 5px;
  padding: 60px 30px;
  text-align: center;
  height: 100%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}
.features-item img {
  z-index: 10;
  position: relative;
  max-width: 100%;
  margin-bottom: 40px;
}
p {
  font-size: 15px;
  line-height: 25px;
  color: #888;
}
@media (max-width: 992px) {
  .features-item {
    margin-bottom: 45px;
  }
}
</style>