<template>
  <section
    class="page-content container reset-password d-flex justify-content-center align-items-center flex-column"
  >
    <div
      class="d-flex flex-column justify-content-center w-100 align-items-center"
    >
      <h1 class="mb-5">{{ $t("RESTORE_PASSWORD") }}</h1>
      <template v-if="code">
        <form class="row" @submit="resetSubmit" v-if="!hasReset">
          <div class="col align-self-center mb-4">
            <input
              type="password"
              minlength="6"
              class="form-control"
              placeholder="Nueva contraseña"
              v-model="resetPassword"
            />
          </div>
          <div class="col-12">
            <button
              type="submit"
              :disabled="v$.$invalid"
              class="btn btn-color"
              v-if="!loading"
            >
              {{ $t("RESTORE") }}
            </button>
            <div class="spinner-border" role="status" v-else></div>
          </div>
        </form>
        <Alert
          v-else
          color="alert-success"
          text="RESTORE_PASSWORD_SUCCESS"
        />
      </template>
      <Alert v-else color="alert-danger" text="NOT_FOUND_RESET_CODE_ERROR" />
    </div>
    <Toast
      v-if="showToast"
      color="bg-danger"
      text="RESTORE_PASSWORD_ERROR"
      @close="showToast = false"
    />
  </section>
</template>
<script lang="ts">
import { useRoute } from "vue-router";
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import axios from "axios";
import ResetPasswordDto from "../models/ResetPasswordDto";
import { required, minLength } from "@vuelidate/validators";
import { patterns } from "../constants/regex";
import Toast from "../components/Toast.vue";
import Alert from "../components/Alert.vue";
import environment from "../environment/environment";

const mustNotSpaces = (value: string) =>
  !new RegExp(patterns.space).test(value);

export default defineComponent({
  name: "ResetPassword",
  components: {
    Toast,
    Alert,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      resetPassword: "",
      code: "",
      hasReset: false,
      loading: false,
      showToast: false,
    };
  },
  validations: {
    resetPassword: {
      required,
      minLength: minLength(6),
      mustNotSpaces,
    },
  },
  mounted() {
    const route = useRoute();
    if (route.query) {
      this.code = route.query?.code as string;
    }
  },
  methods: {
    async resetSubmit(e: any) {
      e.preventDefault();
      const dto = new ResetPasswordDto({ code: this.code, password: this.resetPassword });
      this.showLoading(true);
      this.callApi(dto)
      .then(() => {
          this.showLoading(false);
          this.hasReset = true;
        }, () => {
          this.showLoading(false);
          this.onShowToast();
        }
      );
    },
    showLoading(show: boolean) {
      this.loading = show;
    },
    async callApi(dto: ResetPasswordDto) {
      const URL = `${environment.API_BASE_URL}/auth/reset-password`;
      return await axios.post(URL, dto);
    },
    onShowToast() {
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    },
  },
});
</script>

<style scoped lang="scss">
.reset-password {
  min-height: calc((100vh - 70px));
}
.btn-color {
  background: #f57c00;
}
.spinner-border {
  color: #f57c00;
}
</style>