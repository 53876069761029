<template>
  <section class="page-content container">
    <h1>Política de privacidad</h1>
    <p>
      En PickApp tenemos el máximo interés en proteger los datos de carácter
      personal de nuestros usuarios y por eso le informamos de la presente
      política de privacidad acerca del tratamiento que hacemos de los mismos.
    </p>
    <p>
      <strong>SEGURIDAD</strong>
    </p>
    <p>
      Estamos comprometidos a garantizar que su información esté segura en todo
      momento. Para evitar el acceso o la divulgación no autorizados, hemos
      implementado procedimientos físicos, electrónicos y administrativos
      adecuados para salvaguardar y asegurar la información que recopilamos en
      línea.
    </p>
    <p>
      <strong>RECOGIDA DE DATOS</strong>
    </p>
    <p>
      En cumplimiento de lo dispuesto en la Ley Orgánica 15/1999, de 13 de
      Diciembre, de Protección de Datos de Carácter Personal (LOPD) se informa
      al usuario que todos los datos que nos proporcione serán incorporados a un
      fichero, creado y mantenido bajo la responsabilidad de PickApp S.L
    </p>
    <p>
      En el proceso de registro como Usuario recopilaremos la siguiente
      información.
    </p>
    <ul>
      <li>
        <p>Nombre completo</p>
      </li>
      <li>
        <p>Género humano</p>
      </li>
      <li>
        <p>Nombre de usuario</p>
      </li>
      <li>
        <p>Su dirección de correo electrónico</p>
      </li>
      <li>
        <p>Información demográfica como su ubicación aproximada</p>
      </li>
    </ul>
    <p>
      Como nuevo usuario deberá crear una contraseña. Será responsable de
      mantener la confidencialidad de esta, así como de todas las actividades
      que ocurran en la sesión iniciada con su nombre de usuario y contraseña.
      El Usuario se compromete a notificar a PickApp en la mayor brevedad del
      uso no autorizado de su nombre de usuario y contraseña o cualquier otro
      fallo en la seguridad. PickApp no será responsable por los daños o
      pérdidas que se pudieran originar debido al no cumplimiento de esta
      obligación por su parte.
    </p>
    <p>
      <strong>¿QUÉ HACEMOS CON LA INFORMACIÓN QUE RECOPILAMOS?</strong>
    </p>
    <p>
      Siempre se va a respetar la confidencialidad de sus datos personales que
      sólo serán utilizados con la finalidad de:
    </p>
    <ul>
      <li>
        <p>Gestionar y mejorar los productos y servicios ofrecidos.</p>
      </li>
      <li>
        <p>Atender a las solicitudes que nos plantee.</p>
      </li>
      <li>
        <p>Realizar tareas administrativas.</p>
      </li>
      <li>
        <p>
          Remitir información técnica, comercial o publicitaria por vía
          electrónica.
        </p>
      </li>
      <li>
        <p>
          De vez en cuando, comunicarnos con usted con fines de investigación de
          mercado. Podemos utilizar la información para personalizar el sitio
          web de acuerdo con sus intereses.
        </p>
      </li>
    </ul>
    <p>
      En caso de no desear recibir información publicitaria, comercial o de
      cualquier otro tipo, por favor, remítanos un correo electrónico a
      <a href="mailto:ayuda@pickappbasketball.com">ayuda@pickappbasketball.com</a>
    </p>
    <p>
      No venderemos, distribuiremos ni cederemos su información personal a
      terceros a menos que tengamos su permiso o la ley nos exija hacerlo.
      Podemos utilizar su información personal para enviarle información
      promocional sobre terceros que creemos que puede resultarle interesante si
      nos dice que desea que esto suceda.
    </p>
    <p>
      <strong>ENLACES A OTRAS WEBISTES</strong>
    </p>
    <p>
      Nuestro sitio web puede contener enlaces a otros sitios web de interés.
      Sin embargo, una vez que haya utilizado estos enlaces para salir de
      nuestro sitio, debe tener en cuenta que no tenemos ningún control sobre
      ese otro sitio web. Por lo tanto, no podemos ser responsables de la
      protección y privacidad de cualquier información que usted proporcione
      mientras visita dichos sitios que no se rigen por esta declaración de
      privacidad. Debe tener cuidado y consultar la declaración de privacidad
      aplicable al sitio web en cuestión.
    </p>
    <p>
      <strong>CAMBIOS EN LA POLITICA DE PRIVACIDAD</strong>
    </p>
    <p>
      Esta Política de Privacidad puede cambiar con el tiempo o ser actualizada
      por lo que le recomendamos y enfatizamos revisar continuamente esta página
      para asegurarse que está de acuerdo con dichos cambios. Si cree que la
      información que tenemos sobre usted es incorrecta o está incompleta,
      escríbanos o envíenos un correo electrónico lo antes posible a
      <a href="mailto:ayuda@pickappbasketball.com">ayuda@pickappbasketball.com.</a>
      Corregiremos de inmediato cualquier información que resulte incorrecta.
    </p>
  </section>
</template>
<style scoped lang="scss">
h1{
  margin-bottom: 20px;
}
p {
  text-align: justify;
}
ul {
  text-align: left;
}
</style>