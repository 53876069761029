<template>
  <section class="page page-content container d-flex flex-column justify-content-center align-items-center">
    <h1>¡Cuenta verificada!</h1>
    <p>Tu cuenta ha sido verificada correctamente.</p>
  </section>
</template>
<style scoped lang="scss">
h1{
  margin-bottom: 20px;
}
p {
  text-align: justify;
}
</style>