<template>
  <div :class="color" class="alert" role="alert">
    {{ $t(text) }}
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Alert",
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
});
</script>