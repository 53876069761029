
import { useRoute } from "vue-router";
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import axios from "axios";
import ResetPasswordDto from "../models/ResetPasswordDto";
import { required, minLength } from "@vuelidate/validators";
import { patterns } from "../constants/regex";
import Toast from "../components/Toast.vue";
import Alert from "../components/Alert.vue";
import environment from "../environment/environment";

const mustNotSpaces = (value: string) =>
  !new RegExp(patterns.space).test(value);

export default defineComponent({
  name: "ResetPassword",
  components: {
    Toast,
    Alert,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      resetPassword: "",
      code: "",
      hasReset: false,
      loading: false,
      showToast: false,
    };
  },
  validations: {
    resetPassword: {
      required,
      minLength: minLength(6),
      mustNotSpaces,
    },
  },
  mounted() {
    const route = useRoute();
    if (route.query) {
      this.code = route.query?.code as string;
    }
  },
  methods: {
    async resetSubmit(e: any) {
      e.preventDefault();
      const dto = new ResetPasswordDto({ code: this.code, password: this.resetPassword });
      this.showLoading(true);
      this.callApi(dto)
      .then(() => {
          this.showLoading(false);
          this.hasReset = true;
        }, () => {
          this.showLoading(false);
          this.onShowToast();
        }
      );
    },
    showLoading(show: boolean) {
      this.loading = show;
    },
    async callApi(dto: ResetPasswordDto) {
      const URL = `${environment.API_BASE_URL}/auth/reset-password`;
      return await axios.post(URL, dto);
    },
    onShowToast() {
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    },
  },
});
