export default class ContactDto {
    email: string;
    message: string;
    subject: string;

    constructor(data: any) {
        this.subject = data.subject;
        this.email = data.email;
        this.message = data.message;
    }
}