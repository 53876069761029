<template>
  <section class="section" id="feature">
    <div class="container">
      <div class="row">
        <div class="col pb-5">
          <div class="position-relative mob-wrapper">
            <div class="iphone"></div>
            <div class="border-iphone">
              <transition-group name="fade">
                <img src="@/assets/images/map-page.jpg" v-if="activeTab == 0" />
                <img
                  src="@/assets/images/game-page.jpg"
                  v-if="activeTab == 1"
                />
                <img
                  src="@/assets/images/chat-page.jpg"
                  v-if="activeTab == 2"
                />

                <img
                  src="@/assets/images/filter-page.jpg"
                  v-if="activeTab == 3"
                />
                <img
                  src="@/assets/images/suggestion-page.jpg"
                  v-if="activeTab == 4"
                />
              </transition-group>
            </div>
          </div>
        </div>
        <div class="pb-5 col-sm-12 col-md-3 order-md-first">
          <div class="feature-menu h-100">
            <div>
              <button
                class="tabs"
                :class="{ active: activeTab == 0 }"
                @click="changeTab(0)"
              >
                <BasketballCourtIcon />
              </button>
            </div>
            <div>
              <button
                class="tabs"
                :class="{ active: activeTab == 1 }"
                @click="changeTab(1)"
              >
                <BallIcon />
              </button>
            </div>
            <div>
              <button
                class="tabs"
                :class="{ active: activeTab == 2 }"
                @click="changeTab(2)"
              >
                <ChatBubbleIcon />
              </button>
            </div>
            <div>
              <button
                class="tabs"
                :class="{ active: activeTab == 3 }"
                @click="changeTab(3)"
              >
                <FiltersIcon />
              </button>
            </div>
            <div>
              <button
                class="tabs"
                :class="{ active: activeTab == 4 }"
                @click="changeTab(4)"
              >
                <RatingIcon />
              </button>
            </div>
          </div>
        </div>
        <div class="col d-flex align-items-center">
          <div class="w-100">
            <p class="title">{{ $t($options.FEATURES[activeTab].TITLE) }}</p>
            <h2>{{ $t($options.FEATURES[activeTab].TEXT) }}</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import BasketballCourtIcon from "@/assets/svg/basketball-court.svg";
import ChatBubbleIcon from "@/assets/svg/chat.svg";
import FiltersIcon from "@/assets/svg/filters.svg";
import RatingIcon from "@/assets/svg/favorites.svg";
import BallIcon from "@/assets/svg/ball.svg";

export default defineComponent({
  name: "Feature",
  components: {
    BasketballCourtIcon,
    ChatBubbleIcon,
    FiltersIcon,
    RatingIcon,
    BallIcon,
  },
  FEATURES: [
    {
      TITLE: "FEATURES.COURT.TITLE",
      TEXT: "FEATURES.COURT.TEXT",
    },
    {
      TITLE: "FEATURES.GAME.TITLE",
      TEXT: "FEATURES.GAME.TEXT",
    },
    {
      TITLE: "FEATURES.CHAT.TITLE",
      TEXT: "FEATURES.CHAT.TEXT",
    },
    {
      TITLE: "FEATURES.FILTER.TITLE",
      TEXT: "FEATURES.FILTER.TEXT",
    },
    {
      TITLE: "FEATURES.SUGGESTION.TITLE",
      TEXT: "FEATURES.SUGGESTION.TEXT",
    },
  ],
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    changeTab(index: number) {
      this.activeTab = index;
    },
  },
});
</script>
<style scoped lang="scss">
#feature {
  padding: 100px 0px;
  position: relative;
  z-index: 9;
}
.tabs {
  outline: none;
  border: none;
  background: none;
  padding: 20px 0;
}
.ball {
  width: 167px;
  position: absolute;
  left: 73px;
  top: -73px;
  fill: #f57c00;
}
.title {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
}
ul li {
  display: inline;
}

.active {
  svg {
    fill: #f57c00;
  }
}

svg {
  width: 30px;
}

h2 {
  text-align: left;
  margin-top: 20px;
}

img {
  height: 100%;
  max-width: none;
  // object-fit: cover;
  object-position: center;
  width: 100%;
}
.feature-menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.iphone {
  height: 0;
  padding-bottom: 216%;
  width: 100%;
}
.border-iphone {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10% / 5%;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  width: 100%;
}

.mob-wrapper {
  max-width: 410px;
  width: 21.428vw;
  margin: auto;
}

// .fade-enter-active,
// .fade-leave-active {
//   opacity: 1;
//   transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1);
// }

// .fade-enter,
// .fade-leave-to {
//   transition: opacity 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
//   opacity: 0;
// }

@media (max-width: 768px) {
  .feature-menu {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .tabs {
    padding: 0 12px;
  }

  .mob-wrapper {
    max-width: 200px;
    width: 57.142vw;
  }
}

@media (max-width: 480px) {
  .mob-wrapper {
    max-width: 200px;
    width: 57.142vw;
  }
}

@media (min-width: 769px) {
  .mob-wrapper {
    margin: auto;
    max-width: 342.848px;
  }
}
</style>