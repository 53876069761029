export const doOnFrame = (callback: any, element = window) => {
    const frame = element.requestAnimationFrame || element.webkitRequestAnimationFrame || function (cb: any) { window.setTimeout(cb, 1000 / 60) };
    return frame(callback)
}

export const clipped = (clipped: boolean) => {
    if (clipped) {
        document.documentElement.classList.add('is-clipped')
    } else {
        document.documentElement.classList.remove('is-clipped')
    }
}