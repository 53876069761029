<template>
   <section class="page-content container">
    <h1>Términos y condiciones</h1>
    <p><strong>INTRODUCCIÓN</strong></p>
    <p>
      PickApp es una red social dedicada a todos aquellos que les guste jugar a
      baloncesto. Tratamos de facilitar a los usuarios el cómo, donde y con
      quien pueden jugar a través de una comunidad amplia de jugadores en
      territorio nacional.
    </p>
    <p><strong>EDAD MINIMA DE USUARIO</strong></p>
    <p>
      La utilización de los servicios ofrecidos en PickApp requiere que el
      usuario tenga, al menos, catorce (14) años de edad. Bajo ningún concepto
      se permitirá que usuarios con edad inferior a la mínima permitida puedan
      registrarse ni usar nuestros servicios.
    </p>
    <p>
      <strong>REGISTRO</strong><br />
      Para poder utilizar los servicios, los usuarios deberán registrarse
      proporcionando toda la información solicitada en el correspondiente
      formulario de registro, de forma veraz y completa, y aceptar de forma
      íntegra la política de privacidad y las presentes condiciones generales.
      Los usuarios serán responsables de sus credenciales de acceso y de todo lo
      relacionado con la información de su perfil.
    </p>
    <p><strong>NORMAS DE USO</strong></p>
    <p>
      Nuestras normas de uso ayudan a crear un entorno seguro y abierto para
      todos. Nos tomamos muy en serio las infracciones y queremos que reportes
      cualquier comportamiento abusivo. Reporta las publicaciones que incluyan
      spam o contenido que infrinja las normas.
    </p>
    <p>Es por ello, que los usuarios no podrán:</p>
    <ol>
      <li>
        Menospreciar o atentar contra los derechos fundamentales y las
        libertades públicas reconocidas constitucionalmente.
      </li>
      <li>
        Actuar de ninguna manera que afecte negativamente la capacidad de otros
        usuarios de utilizar nuestros sitios.
      </li>
      <li>
        Interferir o eludir las características de seguridad del Servicio o
        cualquier Sitio relacionado, otros sitios web o Internet. Nos reservamos
        el derecho de finalizar su uso del Servicio o cualquier Sitio
        relacionado por violar cualquiera de los usos prohibidos.
      </li>
      <li>Proporcionar información personal falsa.</li>
      <li>
        Infringir o violar nuestros derechos de propiedad intelectual o los
        derechos de propiedad intelectual de terceros.
      </li>
      <li>
        Publicar contenidos sexuales, ilegales, obscenos, ilegítimos,
        injuriantes o inapropiados.
      </li>
      <li>
        Publicar fotos de pistas o canastas que no se puedan apreciar bien o que se vean de una manera borrosa.
      </li>
      <li>
        Publicar canastas con nombres asociados a empresas privadas. (ej. Escuelas, Bares, Restaurantes, Supermercados, etc…)
      </li>
      <li>
        Defender, promover, incitar a cualquier tercero a cometer o ayudar a
        cualquier acto ilegal o criminal.
      </li>
      <li>Hacer publicidad ilícita, engañosa o desleal.</li>
      <li>Organizar partidos en pistas privadas no autorizadas.</li>
      <li>Crear intencionadamente diferentes cuentas con el mismo correo electrónico.</li>
      <li>
        Hacerse pasar por otra persona o tergiversar su identidad o afiliación
        con cualquier persona.
      </li>
      <li>
        Intentar obtener acceso no autorizado a cualquier servidor, computadora
        o base de datos.
      </li>
      <li>
        Incorporar virus u otros elementos que puedan dañar o impedir el normal
        funcionamiento de la red, del sistema, o de equipos informáticos de
        PickApp, de terceros, o de otros Usuarios.
      </li>
      <li>
        Utilizar PickApp de cualquier forma inadecuada que viole las presentes
        Condiciones.
      </li>
    </ol>
    <p>
      PickApp se reserva el derecho a cerrar la cuenta total o parcial de aquel
      usuario que infrinja alguna o algunas de las situaciones de esta
      declaración. Le notificaremos con anterioridad el motivo del cierre de su
      cuenta.
    </p>
    <p><strong>DENUNCIAR</strong></p>
    <p>
      Los usuarios pueden denunciar a otros usuarios a
      través del perfil de estos pulsando 'Denunciar' y elegir el motivo de la denuncia, así como, denunciar cualquier tipo de partido.
    </p>
    <p><strong>BLOQUEAR</strong></p>
    <p>
      Los usuarios pueden bloquear a otros usuarios directamente desde el perfil de estos, pulsando en 'Bloquear'. El usuario bloqueado, no podrá acceder a tu perfil.
    </p>
    <p><strong>PROPIEDAD INTELECTUAL E INDUSTRIAL</strong></p>
    <p>
      Todas las marcas registradas, nombres comerciales, ilustraciones,
      logotipos o cualquier signo distintivo que aparezca en nuestro Software,
      serán propiedad de PickApp o de terceros. Estos derechos están protegidos
      por las leyes en vigor en materia de marcas y tratados internacionales
      relacionados.
    </p>
    <p>
      Ningún material publicado en esta web podrá ser reproducido, copiado o
      publicado sin el consentimiento por escrito de PickApp.
    </p>
    <p>
      Toda la información que se reciba en la web, como comentarios, sugerencias
      o ideas, se considerará cedida a PickApp de manera gratuita. No debe
      enviarse información que NO pueda ser tratada de este modo.
    </p>
    <p><strong>RESPONSABILIDADES Y GARANTIAS</strong></p>
    <p>
      En PickApp haremos todos los esfuerzos razonables para proporcionarte
      nuestro Servicio y mantener un entorno seguro y libre de errores.
      Procuraremos advertir con suficiente antelación de las interrupciones que
      pudieran suceder en el funcionamiento de nuestros servicios, siempre que
      ello sea posible. No obstante, no podemos garantizar:
    </p>
    <ul>
      <li>
        Que nuestro servicio siempre funcionará sin interrupciones, demoras o
        imperfecciones.
      </li>
      <li>
        La ausencia de virus ni de otros elementos en los servicios prestados
        por terceros a través de PickApp que puedan producir alteraciones en el
        sistema informático del usuario.
      </li>
      <li>
        La licitud, fiabilidad y utilidad de los servicios prestados por
        terceros a través de PickApp.
      </li>
      <li>La veracidad de los datos proporcionados por los usuarios.</li>
      <li>
        Que los usuarios utilicen los servicios y contenidos de PickApp de
        conformidad con las presentes condiciones generales.
      </li>
      <li>
        La total eficacia sobre las medidas de seguridad adoptadas en PickApp.
      </li>
    </ul>
    <p>No nos haremos responsables de:</p>
    <ul>
      <li>
        Daños y perjuicios que se produzcan por fallos o malas configuraciones
        del software instalado en el dispositivo del internauta.
      </li>
      <li>
        Uso que terceros hagan de la información publicada en la App, ni tampoco
        de los daños sufridos o pérdidas económicas que, de forma directa o
        indirecta, produzcan o puedan producir perjuicios económicos, materiales
        o sobre datos, provocados por el uso de dicha información.
      </li>
      <li>
        Cualquier conducta de usted o cualquier otro usuario, fuera del
        servicio.
      </li>
      <li>Muertes, lesiones corporales o cualquier tipo de incidente grave.</li>
      <li>
        Que los usuarios acudan a pistas de carácter privado de forma ilegal.
      </li>
      <li>
        Que los usuarios no acudan a los partidos que se han apuntado o que han
        creado sin previo aviso.
      </li>
    </ul>
    <p><strong>INDEMNIZACIONES</strong></p>
    <p>
      Como usuario, acuerda indemnizar y mantener indemne a PickApp y a nuestras
      empresas matrices, subsidiarias, afiliadas, socios, funcionarios, agentes,
      contratistas, licenciantes, subcontratistas, proveedores, y empleados, de
      cualquier reclamo o demanda, incluyendo los costos razonables honorarios
      de abogados, realizados por terceros debido o que surjan de su
      incumplimiento de estos Términos y condiciones o los documentos que
      incorporan por referencia, o su violación de cualquier ley o los derechos
      de un tercero.
    </p>
    <p><strong>DURACIÓN Y TERMINACIÓN</strong></p>
    <p>
      La prestación de los servicios de la App tiene, en principio, una duración
      indefinida. PickApp, no obstante, podrá dar por terminada o suspender la
      prestación del servicio de la App en cualquier momento, sin perjuicio de
      lo que se hubiere dispuesto al respecto en las correspondientes
      condiciones particulares. Cuando ello sea razonablemente posible, el
      equipo de PickApp advertirá previamente la terminación o suspensión de la
      prestación del servicio de la App. También puede rescindir estos Términos
      y condiciones en cualquier momento, notificándonos que ya no desea
      utilizar Nuestros Servicios.
    </p>
    <p><strong>LEY APLICABLE Y JURISDICCIÓN</strong></p>
    <p>
      Las presentes condiciones generales se rigen por la legislación española.
      Para cualquier litigio que pudiera surgir relacionado con el sitio web o
      la actividad que en él se desarrolla serán competentes Juzgados y
      tribunales de Barcelona, renunciando expresamente el usuario a cualquier
      otro fuero que pudiera corresponderle.
    </p>
  </section>
</template>
<style scoped lang="scss">
h1{
  margin-bottom: 20px;
}
p {
  text-align: justify;
}
ul{
  text-align: left;
}
ol{
  text-align: left;
}
ul li {
  display: list-item;
}
</style>
