<template>
  <div class="welcome-area" id="welcome">
    <div class="header-text h-100">
      <div class="container h-100">
        <div class="row d-flex align-items-center h-100 align-content-center">
          <div class="left-text col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h1>{{ $t("WELCOME.TITLE") }}</h1>
            <p>
              {{ $t("WELCOME.TEXT") }}
            </p>
            <DownloadButtons />
          </div>
          <div class="col-12 col-lg-6 col-md-6 d-none d-sm-block">
            <div class="wrapper">
              <img src="@/assets/images/shape.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import DownloadButtons from "@/components/DownloadButtons.vue";

export default defineComponent({
  name: "Welcome",
  components: {
    DownloadButtons,
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.welcome-area {
  width: 100%;
  height: 100vh;
  min-height: 793px;
  background-image: url("../assets/images/line.jpg");
  background-repeat: no-repeat;
  // background-position: right top;
}
.welcome-area .header-text h1 {
  font-weight: 900;
  font-size: 52px;
  line-height: 80px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  color: white;
}
.welcome-area .header-text h1 em {
  font-style: normal;
  color: #fba70b;
}
.welcome-area .header-text p {
  font-weight: 300;
  font-size: 17px;
  color: white;
  line-height: 30px;
  letter-spacing: 0.25px;
  margin-bottom: 40px;
  position: relative;
}
a {
  text-decoration: none !important;
}
a.main-button-slider {
  font-size: 14px;
  border-radius: 25px;
  padding: 15px 25px;
  background-color: #fba70b;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.wrapper {
  max-width: 410px;
  margin: auto;
  img {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .welcome-area {
    min-height: 1400px;
  }
}

@media (min-width: 768px) {
  .welcome-area {
    min-height: 793px;
  }
}
</style>
