<template>
  <section class="menu">
    <div class="background"></div>
    <div class="container">
      <ul class="d-flex flex-column justify-content-center h-100">
        <li class="p-4 fs-1">
          <router-link to="/" @click="$emit('closeMenu')">{{
            $t("MENU.HOME")
          }}</router-link>
        </li>
        <li class="p-4 fs-1">
          <router-link to="/about" @click="$emit('closeMenu')">{{
            $t("MENU.ABOUT")
          }}</router-link>
        </li>
        <li class="p-4 fs-1">
          <router-link to="/contact" @click="$emit('closeMenu')">{{
            $t("MENU.CONTACT")
          }}</router-link>
        </li>
        <li class="p-4 fs-1">
          <a href="http://blog.pickappbasketball.com/" target="_blank">{{
            $t("MENU.BLOG")
          }}</a>
        </li>
        <li class="p-4 fs-1">
          <a href="http://ayuda.pickappbasketball.com/" target="_blank">{{
            $t("MENU.HELP")
          }}</a>
        </li>
      </ul>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { clipped } from "../utils/style";
export default defineComponent({
  name: "Menu",
  mounted() {
    clipped(true);
  },
  unmounted() {
    clipped(false);
  },
});
</script>
<style scoped lang="scss">
.menu {
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
}
.background {
  background: black;
  opacity: 0.5;
  height: 100vh;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.list {
  height: 100%;
}
.container {
  height: 100%;
}
a {
  color: white;
}
</style>