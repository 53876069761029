
import { defineComponent } from "vue";
import { clipped } from "../utils/style";
export default defineComponent({
  name: "Menu",
  mounted() {
    clipped(true);
  },
  unmounted() {
    clipped(false);
  },
});
