<template>
  <section
    class="page page-content container d-flex flex-column justify-content-center align-items-center"
  >
    <NotFoundIcon />
    <h1>¡Ups! Air Ball</h1>
    <p>
      Página no encontrada.
    </p>
    <DownloadButtons />
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import NotFoundIcon from "@/assets/svg/ball.svg";
import DownloadButtons from "@/components/DownloadButtons.vue";

export default defineComponent({
  components: {
    NotFoundIcon,
    DownloadButtons
  },
});
</script>

<style scoped lang="scss">
h1 {
  font-weight: 600;
}
p {
  color: #92949c;
}
svg {
  width: 100px;
  fill: #f57c00;
  margin-bottom: 8px;
}

.download__title {
    font-size: 20px !important;
}
</style>