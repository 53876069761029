<template>
  <div class="home">
    <Welcome />
    <Service />
    <Feature />
    <Parallax />
    <!-- <Testimonials /> -->
    <Newsletter/>
    <Download />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Welcome from '@/components/Welcome.vue';
import Service from '@/components/Service.vue';
import Feature from '@/components/Feature.vue';
// import Testimonials from '@/components/Testimonials.vue';
import Download from '@/components/Download.vue';
import Newsletter from '@/components/Newsletter.vue';
import Parallax from '@/components/Parallax.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Welcome,
    Service,
    Feature,
    // Testimonials,
    Parallax,
    Newsletter,
    Download,
  },
});
</script>
