
import { defineComponent } from "vue";
import axios from "axios";
import ContactDto from "../models/ContactDto";
import { required, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import environment from "../environment/environment";

export default defineComponent({
  name: "Contact",
  data() {
    return {
      showContact: true,
      showToast: false,
      loading: false,
      form: {
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      message: {
        required,
      },
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  methods: {
    sendMessage(e: any) {
      e.preventDefault();
      this.showLoading(true);
      this.callApi(
        new ContactDto({
          subject: this.form.subject,
          email: this.form.email,
          message: this.form.message,
        })
      ).then(
        (data) => {
          this.showLoading(false);
          this.showContact = false;
        },
        (err) => {
          this.showLoading(false);
          this.onShowToast();
        }
      );
    },
    onShowToast() {
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    },
    showLoading(show: boolean) {
      this.loading = show;
    },
    async callApi(dto: ContactDto) {
      const URL = `${environment.CONTACT_URL}/contact`;
      return await axios.post(URL, dto);
    },
  },
});
