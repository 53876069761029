<template>
  <app-header />
  <section>
    <router-view />
  </section>
  <app-footer />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

a {
  text-decoration: none;
  display: inline-block;
}
ul li {
  display: inline-block;
}
.page-content {
  padding-top: 72px
}
html, body {
  height: 100%;
}
.page {
  min-height: calc((100vh - 70px));
}
.is-clipped {
  overflow: hidden;
}
</style>
