<template>
  <section class="container" id="newsletter">
    <iframe
      class="mj-w-res-iframe"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      src="https://app.mailjet.com/widget/iframe/6Lm8/IZ9"
      width="100%"
    ></iframe>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Newsletter"
});
</script>
<style scoped lang="scss">
#newsletter {
  padding: 100px 12px;
  position: relative;
  z-index: 9;
}
h4 {
  font-weight: 700;
}
p {
  color: rgb(176, 179, 184);
  font-size: 16px;
}
.newsletter {
  padding: 15px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 35%) 0px 0px 20px;
  max-width: 490px;
  margin: auto;
}
.btn-color {
  background: #f57c00;
  color: white;
  font-weight: 700;
  width: 100%;
  font-size: 13px;
}
.mj-w-res-iframe {
  max-width: 600px;
  min-height: 440px !important;
}
</style>