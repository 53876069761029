
import { defineComponent } from "vue";
import Menu from "@/components/Menu.vue";

export default defineComponent({
  name: "Header",
  components: {
    Menu,
  },
  data() {
    return {
      openMenu: false,
    };
  }
});
