<template>
  <div
    :class="color"
    class="toast show mb-5 align-items-center text-white border-0 position-absolute bottom-0 start-50 translate-middle-x"
    role="alert"
  >
    <div class="d-flex">
      <div class="toast-body">
        {{ $t(text) }}
      </div>
      <button
        type="button"
        @click="$emit('close')"
        class="btn-close btn-close-white me-2 m-auto"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Toast",
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
});
</script>

<style scoped lang="scss">
.primary {
  background: #f57c00;
}
</style>